import React, { memo, useState } from "react";
import { Button, Typography, Box, Divider, Link } from "@mui/material";
import GLogo from "../../assets/light/logo/google_logo.svg";
import "./styles.scss";
import TextBox from "../TextBox";
import AppButton from "../AppButton";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SignInForm: React.FC<any> = (props) => {
  const { handleGoogleLogin, loading } = props;
  const [visibility, setVisibility] = useState({
    pass: false,
    confirmPass: false,
  });

  return (
    <Box
      className="box-container"
      sx={{
        width: 360,
        margin: "auto",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "start",
        }}
      >
        <h4 className="sign-in-header">Login</h4>
      </Box>

      <Button
        className="google-login-button"
        variant="contained"
        sx={{
          textTransform: "none",
          borderColor: "#dadce0",
          color: "#3c4043",
          width: "100%",
          padding: "8px 15px",
          fontWeight: 500,
          fontSize: "14px",
          borderRadius: "8px",
          "&:hover": {
            borderColor: "#dadce0",
            backgroundColor: "#f8f9fa",
          },
        }}
        onClick={handleGoogleLogin}
      >
        <Box
          component="img"
          src={GLogo}
          alt="Google logo"
          sx={{
            height: 20,
            width: 20,
            marginRight: "8px",
          }}
        />
        <div className="label">Sign in with Google</div>
      </Button>

      <Divider className="divider" sx={{ width: "100%", marginY: "16px" }}>
        Or
      </Divider>

      <Box className="text-field-container">
        <Controller
          name="email"
          rules={{
            required: "Email is required for signin",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email",
            },
          }}
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <TextBox
              preLabel="Email"
              placeholder={"Enter your email"}
              errorMessage={error?.message}
              value={value || ""}
              {...rest}
            />
          )}
        />
        <Controller
          name="password"
          rules={{
            required: "Password is required for signup",
            pattern: {
              value:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              message: "passsword is invalid",
            },
          }}
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <TextBox
              preLabel="Password"
              placeholder={"Create a password"}
              // hint={"Must be at least 8 characters."}
              errorMessage={error?.message}
              type={visibility.pass ? "text" : "password"}
              icon={{
                position: "end",
                icon: !visibility.pass ? (
                  <VisibilityIcon className="cursor-point" />
                ) : (
                  <VisibilityOffIcon className="cursor-point" />
                ),
                onClick: () => {
                  setVisibility((prev) => ({ ...prev, pass: !prev.pass }));
                },
              }}
              value={value || ""}
              {...rest}
            />
          )}
        />
        <Box className="forgot-cont">
          <Link className="forgot-pass" href="forgot-password" underline="none">
            Forgot Password?
          </Link>
        </Box>
      </Box>

      <AppButton
        loading={loading}
        label="Login"
        type="submit"
        className="login-btn"
      />

      <Typography variant="body2" sx={{ marginTop: "20px" }}>
        New to Pocketbud?{" "}
        <Link href="sign-up" underline="none">
          Sign Up
        </Link>
      </Typography>
    </Box>
  );
};

export default memo(SignInForm);
