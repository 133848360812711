import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as NotificationIcon } from "../../assets/light/NotificationIcon.svg";
import { ReactComponent as NotificationDark } from "../../assets/dark/NotificationIcon.svg";
import { ReactComponent as ThemeChange } from "../../assets/light/ThemeChange.svg";
import { ReactComponent as MenuBurger } from "../../assets/light/menu-burger.svg";
import { ReactComponent as MenuBurgerDark } from "../../assets/dark/menu-burger.svg";
import { ReactComponent as Logout } from "../../assets/light/Logout.svg";
import { ReactComponent as Profile } from "../../assets/light/UserProfile.svg";
import { ReactComponent as ThemeDark } from "../../assets/dark/Sun.svg";
import AppAvatar from "../../components/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { settings } from "../../redux/Reducer/settingsReducer";
import { logout } from "../../redux/Reducer/userReducer";
import AppPopover from "../../components/Popover";
import NotificationsComponent from "../../components/Notification";
import { Notification } from "../../utils/types";
import { ErrorLogService } from "../../services/errorLog.service";
import { storeDataInLocalSQL } from "../../utils/helper";
import { chartData, resetChart } from "../../redux/Reducer/chartDataReducer";
import { LocalDB } from "../../utils/sql";
import { RootState } from "../../redux/Reducer";
import { clearMessages } from "../../redux/Reducer/webSocketReducer";
import PlansView from "../../components/subscriptionPlans";
import { SubscriptionStatus } from "../../utils/constants";

interface IProps {
  setSideOpen: (e: boolean) => void;
}

const TopBar: React.FC<IProps> = ({ setSideOpen }) => {
  const errorService = new ErrorLogService();
  const headerDecider: Record<string, string> = useMemo(
    () => ({
      "/analyser": "Pibi Analyser",
      "/dashboard": "Dashboard",
      "/studyPlan": "Study Plan",
      "/profile": "My Profile",
    }),
    []
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const matches = useMediaQuery("(min-width:700px)");
  const header: string = useMemo(() => {
    return headerDecider?.[location?.pathname] || "Not Decided Yet";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const chartLoadMore = useSelector(
    (state: RootState) => state?.chartData?.hasMoreData
  );
  const messages = useSelector((state: any) => state.websocket.messages);

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [showPlans, setShowPlans] = useState<boolean>(false);
  const [notificationPopper, setNotificationPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    setNotifications(messages);
  }, [messages]);

  const ThemeDarkEdit = () => (
    <div className="iconfit">
      <ThemeDark />
    </div>
  );

  const getErrorLogs = async () => {
    let page = 0;
    let pageSize = 1000;
    let hasMoreData = true;
    if (LocalDB) LocalDB.exec("delete from error_logs");
    let lastPageDetails = {
      page: 0,
      pageSize: 1000,
      dataLength: 0,
    };
    while (hasMoreData) {
      const resData = await errorService.GetErrorLog(page, pageSize);
      lastPageDetails = { page, pageSize, dataLength: resData?.data?.length };
      if (resData?.data?.length === pageSize) {
        page += 1;
        storeDataInLocalSQL(resData?.data);
        dispatch(
          chartData({
            isLoggedOut: false,
            isAuthenticated: true,
            hasMoreData,
          } as any)
        );
      } else {
        hasMoreData = false;
        storeDataInLocalSQL(resData?.data);
        dispatch(
          chartData({
            isLoggedOut: false,
            isAuthenticated: true,
            hasMoreData,
            lastPageDetails,
          } as any)
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetChart());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartLoadMore) getErrorLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartLoadMore]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen((prev) => !prev);
  };
  const handleNotificationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setNotificationAnchorEl(event.currentTarget);
    setNotificationPopper((prev) => !prev);
  };

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add("dark-mode");
    } else {
      root.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  const allSeen = useMemo(
    () => notifications.every((x) => x.isread),
    [notifications]
  );

  return (
    <Box className="top-bar-container">
      <Box className="left-content">
        {!matches && (
          <div style={{ cursor: "pointer" }} onClick={() => setSideOpen(true)}>
            {isDarkMode ? <MenuBurgerDark /> : <MenuBurger />}
          </div>
        )}
        <p style={{ margin: "0 0 5px 0" }}>{header}</p>
      </Box>
      <Box className="right-content">
        <div
          className={`pointer-div ${!allSeen ? "available" : ""}`}
          onClick={handleNotificationClick}
        >
          {isDarkMode ? <NotificationDark /> : <NotificationIcon />}
        </div>
        <div
          className="pointer-div"
          onClick={() => dispatch(settings({ darkMode: !isDarkMode }))}
        >
          {isDarkMode ? <ThemeDarkEdit /> : <ThemeChange />}
        </div>
        <AppAvatar
          className="app-avatar"
          name={userDetail.nickName}
          onClick={handleClick}
        />
      </Box>
      <AppPopover
        open={popoverOpen}
        setOpen={setPopoverOpen}
        anchorEl={anchorEl}
      >
        <Box className="user-popover">
          <Box className="first-set">
            <AppAvatar name={userDetail.nickName} />
            <Box>
              <p className="inner-header">{userDetail.nickName} </p>
              <p className="exp-content">{userDetail.email}</p>
            </Box>
          </Box>
          <Box className="second-set">
            <Box
              className="each-set colored"
              onClick={() => {
                setPopoverOpen(false);
                navigate("/profile");
              }}
            >
              <Profile />
              <p className="title">Account & Profile</p>
            </Box>
            {userDetail?.subStatus !== SubscriptionStatus.Active && (
              <Box
                className="each-set colored"
                onClick={() => {
                  setPopoverOpen(false);
                  setShowPlans(true);
                }}
              >
                <Profile />
                <p className="title">Subscribe</p>
              </Box>
            )}
            <Box
              className="each-set"
              onClick={() => {
                dispatch(clearMessages());
                dispatch(logout());
              }}
            >
              <Logout />
              <p className="title">Logout</p>
            </Box>
          </Box>
        </Box>
      </AppPopover>
      <AppPopover
        open={notificationPopper}
        setOpen={setNotificationPopper}
        anchorEl={notificationAnchorEl}
      >
        <NotificationsComponent
          notifications={notifications}
          setNotifications={setNotifications}
          setNotificationPopper={setNotificationPopper}
        />
      </AppPopover>
      {showPlans && (
        <PlansView
          onClose={() => setShowPlans(false)}
          onProcessed={(id: any) => {
            setShowPlans(false);
          }}
        />
      )}
    </Box>
  );
};

export default TopBar;
