import { Box, Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AppAvatar from "../../components/Avatar";
import "./styles.scss";
import AppTabs from "../../components/Tabs";
import Examination from "../../components/profiletabs/examination";
import Notification from "../../components/profiletabs/notification";
import Password from "../../components/profiletabs/password";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../utils/helper";
import axios from "axios";
import AppButton from "../../components/AppButton";
import { toast } from "react-toastify";
import { PaymentService } from "../../services/payment.service";
import { SubscriptionStatus } from "../../utils/constants";
import PlansView from "../../components/subscriptionPlans";

const Profile = () => {
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("exams");
  const [subscription, setSubscription] = useState<any>({});
  const [showPlans, setShowPlans] = useState<boolean>(false);
  const tabsList = [
    { label: "Exams Settings", value: "exams" },
    { label: "Notifications", value: "notification" },
    // { label: "Password", value: "password" },
  ];
  const paymentService = new PaymentService();

  const SelectedContainer = useCallback(() => {
    let children = <></>;
    switch (activeTab) {
      case "exams":
        children = <Examination />;
        break;
      case "notification":
        children = <Notification />;
        break;
      case "password":
        children = <Password />;
        break;

      default:
        break;
    }
    return <Box className="profile-content-container">{children}</Box>;
  }, [activeTab]);

  const triggerPasswordReset = async () => {
    setLoading(true);
    try {
      await axios({
        method: "POST",
        url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: {
          "content-type": "application/json",
        },
        data: {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: userDetail.email,
          connection: process.env.REACT_APP_AUTH0_DB,
        },
      });
      toast.success("Password reset email sent");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(
        "Error triggering password reset:",
        error.response?.data || error.message
      );
    }
  };

  const triggerCancelSubscription = async () => {
    setLoading(true);
    try {
      await paymentService.CancelSubscription({
        subscriptionId: userDetail?.planId,
      });
      toast.success("Subscription Cancelled Successfully");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(
        "Error triggering Subscription Cancel:",
        error.response?.data || error.message
      );
    }
  };

  const getSubscriptionDetails = async () => {
    const subscription = await paymentService.GetSubscription(
      userDetail?.planId
    );
    setSubscription(subscription.data);
  };

  useEffect(() => {
    if (!!userDetail?.planId) {
      getSubscriptionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail?.planId]);

  return (
    <Box className="profile-container">
      <Box className="left-container">
        <Box className="avatar-container">
          <AppAvatar name={userDetail?.nickName} className="profile-avatar" />
          <Box className="profile-details">
            <p className="username">{userDetail?.nickName}</p>
            <p className="useremail">{userDetail?.email}</p>
          </Box>
        </Box>
        <Box className="basic-details-container">
          <p className="header-text">Basic details</p>
          <Divider sx={{ width: "100%" }} />
          <Box className="basic-details">
            <p className="head">Name</p>
            <p className="data">
              {userDetail?.userName ||
                userDetail?.firstName ||
                userDetail?.nickName}
            </p>
          </Box>
          <Box className="basic-details">
            <p className="head">Exam date</p>
            <p className="data">
              {dateFormatter(userDetail?.examDate, "MMMM DD, YYYY")}
            </p>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <AppButton
            label="Reset Password"
            onClick={triggerPasswordReset}
            className="save-btn"
            loading={loading}
          />
        </Box>
        <Box className="basic-details-container">
          <p className="header-text">Subscription</p>
          <Divider sx={{ width: "100%" }} />
          {userDetail?.subStatus === SubscriptionStatus.Active && (
            <>
              <Box className="basic-details">
                <p className="head">Plan name</p>
                <p className="data">{subscription?.plan?.item?.name}</p>
              </Box>
              <Box className="basic-details">
                <p className="head">Subscription valid till</p>
                <p className="data">
                  {dateFormatter(userDetail?.subEndDate, "MMMM DD, YYYY")}
                </p>
              </Box>
            </>
          )}
          <Divider sx={{ width: "100%" }} />
          {userDetail?.subStatus === SubscriptionStatus.Active ? (
            <AppButton
              label="Cancel"
              onClick={triggerCancelSubscription}
              className="save-btn"
              loading={loading}
            />
          ) : (
            <AppButton
              label="Subscribe"
              onClick={() => setShowPlans(true)}
              className="save-btn"
              loading={loading}
            />
          )}
        </Box>
      </Box>
      <Box className="tabs-container">
        <AppTabs
          value={activeTab}
          onChange={(_, val) => {
            setActiveTab(val);
          }}
          tabsList={tabsList}
          className="profile-tabs"
        />
        <SelectedContainer />
      </Box>
      {showPlans && (
        <PlansView
          onClose={() => setShowPlans(false)}
          onProcessed={(id: any) => {
            setShowPlans(false);
          }}
        />
      )}
    </Box>
  );
};

export default Profile;
