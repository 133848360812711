import { SubscriptionPlane } from "./constants";
import { planType } from "./types";

export const plans: { [key in SubscriptionPlane]: planType } = {
    Free: {
        noOfLogsAllowed: 100
    },
    Premium: {
        noOfLogsAllowed: 100000
    }
}