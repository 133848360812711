import React, { useState, useEffect } from "react";
import "./style.scss";
import { PaymentService } from "../../services/payment.service";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../redux/Reducer/userReducer";
import { Loader } from "../Loader";

const PlansDialog = ({ onClose, onProcessed }: any) => {
  const [plans, setPlans] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [verificationDetails, setVerificationDetails] = useState<any>(null);
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  const dispatch = useDispatch();

  const paymentService = new PaymentService();

  useEffect(() => {
    fetchPlans();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlans = async () => {
    try {
      const data = await paymentService.GetPlans();

      setPlans(data.data);
      console.log(Math.round((data.data.length - 1) / 2));
      setActiveIndex(Math.round((data.data.length - 1) / 2));
    } catch (err) {
      setError("Failed to fetch plans");
    } finally {
      setLoading(false);
    }
  };

  const handleOverlayClick = (e: any) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleCardClick = (index: any) => {
    setActiveIndex(index);
  };

  const updateSubscription = async () => {
    const verifyResponse: any = await paymentService.VerifyPayment(
      verificationDetails
    );
    setIsLoading(false);
    dispatch(updateUserData(verifyResponse.data.data));
    onProcessed(false);
  };

  useEffect(() => {
    if (verificationDetails) {
      updateSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDetails]);

  const startPaymentProcess = async (id: string) => {
    try {
      const orderdetail = await paymentService.PostPayment({
        planId: id,
      });
      const options = {
        key_id: process.env.REACT_APP_RAZORPAY_KEYID as string, // Razorpay Key ID
        amount: orderdetail.data.amount,
        currency: orderdetail.data.currency,
        name: "Pocketbud",
        description: "Pocketbud subscription",
        subscription_id: orderdetail.data.id, // Razorpay Order ID from backend
        handler: async (response: any) => {
          setVerificationDetails(response);
        },
        prefill: {
          name: userDetail.name,
          email: userDetail.email,
        },
        theme: {
          color: "#F37254",
        },
        modal: {
          ondismiss: function () {
            setIsLoading(false);
            onProcessed(false);
          },
          // Optional: Handle escape key press
          escape: true,
        },
      };
      const win: any = window;
      const razorpay = win.Razorpay(options);
      razorpay.open();
    } catch (error: any) {
      console.log("Error", error.message);
    }
  };

  return (
    <div className="dialog-overlay" onClick={handleOverlayClick}>
      <div className="dialog-content">
        {!isLoading && (
          <button className="close-button" onClick={onClose}>
            ×
          </button>
        )}

        <div className="plans-container">
          <h1 className="plans-title">Choose Your Plan</h1>

          {loading && <div className="loading">Loading plans...</div>}

          {error && <div className="error">{error}</div>}

          {!loading && !error && (
            <>
              <div className="plans-stack">
                {plans.map((plan: any, index: any) => {
                  const offsetZ = 20 * (index - activeIndex);
                  const offsetX = 150 * (index - activeIndex);
                  const scale = 1 - 0.05 * Math.abs(index - activeIndex);
                  const opacity = 1 - 0.2 * Math.abs(index - activeIndex);
                  return (
                    <div
                      key={plan.id}
                      className={`plan-card ${
                        index === activeIndex ? "active" : ""
                      }`}
                      style={
                        {
                          "--index": index,
                          "--active-index": activeIndex,
                          "--total-cards": plans.length,
                          opacity,
                          transform: `translateZ(${
                            offsetZ * -1
                          }px) translateX(${offsetX * -1}px) scale(${scale})`,
                          zIndex:
                            (plans.length - Math.abs(index - activeIndex)) *
                            100,
                        } as React.CSSProperties
                      }
                      onClick={() => handleCardClick(index)}
                    >
                      <h2 className="plan-name">{plan.name}</h2>
                      {plan.description
                        .split("\n")
                        .map((description: string) => {
                          return (
                            <div className="plan-description">
                              {description}
                            </div>
                          );
                        })}
                      <div className="plan-price">
                        {plan.currency} {plan.amount}
                      </div>
                      <div className="plan-period">
                        {plan.interval} / {plan.period}
                      </div>
                      <button
                        className="subscribe-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsLoading(true);
                          startPaymentProcess(plan.id);
                          console.log("Subscribe to plan:", plan.id);
                        }}
                      >
                        Subscribe
                      </button>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <Loader open={isLoading} />
    </div>
  );
};

export default PlansDialog;
