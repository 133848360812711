import { BaseService } from "./base.service";


export class PaymentService extends BaseService {
    public async PostPayment(body: any) {
        try {
            const { data, status } = await this.httpClient.post("payment", body);

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
        }
    }
    public async GetPlans() {
        try {
            const { data, status } = await this.httpClient.get("payment");

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
        }
    }
    public async GetSubscription(id: string) {
        try {
            const { data, status } = await this.httpClient.get(`payment/plan?id=${id}`);

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
        }
    }
    public async VerifyPayment(body: any) {
        try {
            const { data, status } = await this.httpClient.post("payment/verify", body);

            if (status === 200) {
                return { data, success: true };
            }
        }
        catch (error) {
        }
    }
    public async CancelSubscription(body: any) {
        try {
            const { status } = await this.httpClient.post("payment/cancel", body);

            if (status === 200) {
                return { success: true };
            }
        }
        catch (error) {
        }
    }
}